import * as React from 'react'
import qs from 'querystring'
import {navigate} from 'gatsby'
import ReactConfetti from 'react-confetti'

import Button from '@littlestores/common/components/Button'


import {HOME} from '../../constants/paths'
import decorateWithOrdersContext from '../../hoc/OdersHOC'

import WhatsAppIcon from '../Checkout/images/whatsapp.svg'

import styles from './Placed.module.scss'
import SEO from "../seo";

function Placed({ordersContext}) {
    React.useEffect(() => {
        ordersContext.clearOrders()

    }, [])

    const {shopName} = ordersContext

    let querystring = {},
        orders = []

    if (typeof window === 'object') {
        querystring = qs.parse(window.location.search.substring(1))
        orders = JSON.parse(querystring.orders)
    }

    const ordersText = orders
        .reduce(
            (text, orderItem) => text + `→ *${orderItem.title}*   x ${orderItem.units} - ₹${orderItem.price * orderItem.units}\n`,
            '')

    const total = orders
        .reduce(
            (totalAmount, orderItem) => totalAmount + (orderItem.price * orderItem.units)
            , 0
        )

    const text = encodeURIComponent(`
Hi,
I would like to place an order.
Order Id: *${querystring.id}*
>> Order Details <<
${ordersText}
-------------------------------
Total Order Amount *₹${total}*
`)

    return (
        <>
            <SEO title={`${shopName}`} />

            <div>
                <ReactConfetti
                    colors={[
                        '#fff7e0',
                        '#048647',
                        '#1867d1',
                        '#f0894d',
                        '#ed6654',
                        '#de577d',
                    ]}
                    numberOfPieces={850}
                    recycle={false}
                    tweenDuration={18500}
                    className={styles['Confetti']}
                />
                <div className={styles.Placed}>
                    <h2 style={{marginBottom: '48px'}}>Congratulations!</h2>
                    <h4>Your order id is: #{querystring.id}</h4>
                    <div style={{textAlign:"center"}}>Share your order with merchant on WhatsApp</div>
                    <div  className={styles.PlacedContainer}>
                        <div><a href={`https://api.whatsapp.com/send?phone=${querystring.merchant}&text=${text}`}
                                className={styles['Share']}><span>Share</span><span><img src={WhatsAppIcon} alt="WA"
                                                                                         height="18" style={{
                            marginLeft: '4px',
                            marginBottom: '0'
                        }}/></span></a></div>
                        <Button secondary onClick={() => navigate(HOME)}>Home</Button>
                    </div>

                </div>
            </div>

        </>
    )
}

export default decorateWithOrdersContext(Placed)
