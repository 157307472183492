const HOME = '/home'
const CHECKOUT = '/checkout'
const PLACED = '/placed'
const NOTFOUND = '/not-found'


export {
  HOME,
  CHECKOUT,
  PLACED,
    NOTFOUND
}
