import React from 'react'
import OrdersContextConsumer from './../context/OrderContextConsumer'

function decorateWithOrdersContext(WrappedComponent) {
  return (props) => (
    <OrdersContextConsumer>
      {(context) => <WrappedComponent ordersContext={context} {...props} />}
    </OrdersContextConsumer>
  )
}

export default decorateWithOrdersContext
